<template>
  <div>
    <v-row no-gutters>
      <v-col sm="12">
        <redirect title="Updates" route="website.bulkupdates" />
        <div class="mt-0 page-header">
          <h2 class="page-title mt-2 mb-2">Create an Update</h2>
        </div>
      </v-col>
    </v-row>
    <div class="elevation-form pa-3 mt-0">
      <v-row>
        <v-col sm="12" class="d-flex justify-space-between">
          <h4>Filter</h4>
          <div class="primary--text learn-more">Learn more about filter conditions</div>
        </v-col>
      </v-row>
      <div>What products do you want to mass update?</div>
      <div class="d-flex">
        <div class="mx-0 my-auto">Products must match:</div>
        <div class="ml-8">
          <v-radio-group v-model="filterType" row>
            <v-radio label="all condition" value="all"></v-radio>
            <v-radio label="any" value="any"></v-radio>
          </v-radio-group>
        </div>
      </div>
      <v-row v-for="(item2, index) in filterOption" class="align-center" :key="index">
        <v-col sm="5">
          <v-select
            placeholder="Product title"
            dense
            :items="itemsFilter1"
            item-value="value"
            item-text="title"
            v-model="item2.column"
          ></v-select>
        </v-col>
        <v-col sm="3">
          <!-- {{ itemsFilter1.find(i => i.value === item.column).compare }} -->
          <v-select
            dense
            :items="itemsFilter2"
            item-value="value"
            item-text="title"
            placeholder="contains"
            v-model="item2.relation"
            item-disabled="customDisabledFilter"
          >
            <template v-slot:item="{ on, item }">
              <v-list-item v-on="on" :disabled="checkDisableFilterCompare(item, item2.column).disable">
                {{ item.title }}
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col sm="3">
          <v-text-field placeholder="typing here" dense v-model="item2.condition"></v-text-field>
        </v-col>
        <v-col class="pt-0"
          ><v-btn class="delete-btn" color="primary" outlined @click="reduceFilterOption(index)" v-if="index > 0"
            ><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn
          ></v-col
        >
      </v-row>
      <v-btn class="primary" @click="addFilterOption">Add condition</v-btn>
    </div>
    <div class="elevation-form pa-3">
      <v-row>
        <v-col sm="4">
          <v-checkbox
            style=""
            @click="turnUpdateVariant"
            label="Only update some variants"
            :value="isUpdateVariant"
          ></v-checkbox>
        </v-col>
      </v-row>
      <div v-if="isUpdateVariant">
        <div class="d-flex">
          <div class="mx-0 my-auto">Variants must match:</div>
          <div class="ml-8">
            <v-radio-group v-model="variantType" row>
              <v-radio label="all condition" value="all"></v-radio>
              <v-radio label="any" value="any"></v-radio>
            </v-radio-group>
          </div>
        </div>

        <v-row v-for="(item3, index) in variantOption" class="align-center" :key="index">
          <v-col sm="5">
            <v-select
              dense
              placeholder="Variant title"
              :items="itemsVariant1"
              item-value="value"
              item-text="title"
              v-model="item3.column"
            ></v-select>
          </v-col>
          <v-col sm="3">
            <!-- {{ item.column }} -->
            <v-select
              dense
              placeholder="contains"
              :items="itemsVariant2"
              item-value="value"
              item-text="title"
              v-model="item3.relation"
              item-disabled="customDisabledVariant"
            >
              <template v-slot:item="{ on, item }">
                <v-list-item v-on="on" :disabled="checkDisableVariantCompare(item, item3.column).disable">
                  {{ item.title }}
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col sm="3">
            <v-text-field value="0" dense v-model="item3.condition"></v-text-field>
          </v-col>
          <v-col class="pt-0">
            <v-btn class="delete-btn" color="primary" outlined @click="reduceVariantOption(index)" v-if="index > 0"
              ><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-btn class="primary" @click="addVariantOption">Add condition</v-btn>
      </div>
    </div>
    <div class="elevation-form pa-3">
      <div><strong>Note:</strong> all currencies will be shop's currency</div>
    </div>
    <div class="elevation-form pa-3 mt-5">
      <v-row>
        <v-col sm="12" class="d-flex justify-space-between">
          <h4>Action</h4>
          <div class="primary--text learn-more">Learn more about supported actions</div>
        </v-col>
        <v-col>How do you want to update the product?</v-col>
      </v-row>
      <!-- ################# Product -->
      <!-- ################# Add custom option -->
      <v-row class="my-0" v-for="(item, ind) in listFormAction" :key="ind">
        <v-col class="d-flex" :sm="checkActionType(item)">
          <v-select
            :height="42"
            v-model="item.actionType"
            item-text="title"
            item-value="value"
            :items="listAction"
            placeholder="Select an action"
            dense
            hide-details=""
            item-disabled="disableAction"
            @change="checkAction(item, ind)"
          ></v-select>
        </v-col>
        <!-- ################# change_product_description_to -->
        <v-col v-if="item.actionType == 'change_product_description_to'" sm="12">
          <changeProductDescriptionTo class="py-0" :index="ind" />
        </v-col>
        <!-- ################# remove tag -->
        <!-- condition remove -->
        <v-col sm="3" v-if="item.actionType == 'remove_tags'">
          <v-select
            :height="42"
            :items="item.listConditionTag"
            item-value="value"
            item-text="title"
            v-model="item.condition"
            placeholder="Select a type"
          ></v-select>
        </v-col>
        <!-- Value remove -->
        <v-col sm="4" v-if="item.condition != 'equals' && item.actionType == 'remove_tags'">
          <v-text-field :height="42" v-model="item.value"></v-text-field>
        </v-col>
        <!-- Value remove -->
        <v-col sm="4" v-if="item.condition == 'equals' && item.actionType == 'remove_tags'">
          <!-- <v-autocomplete :items="listTag" v-model="item.valueArray" multiple chips>
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="removeTagNameRemove(data.item, ind)"
              >
                {{ utilities.stringLimit(data.item, 30) }}
              </v-chip>
            </template>
          </v-autocomplete> -->
          <v-combobox
            clearable
            :delimiters="[',']"
            @change="checkEmptyTag(item)"
            v-model="item.valueArray"
            :items="listTag"
            multiple
            chips
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click:close="removeTagNameRemove(data.item, ind)"
              >
                {{ utilities.stringLimit(data.item, 30) }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <!-- ################# add tag -->
        <!-- condition add -->
        <v-col sm="6" v-if="item.actionType == 'add_tags'">
          <v-combobox
            clearable
            :delimiters="[',']"
            @change="checkEmptyTag(item)"
            v-model="item.valueArray"
            :items="listTag"
            multiple
            chips
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click:close="removeTagNameAdd(data.item, ind)"
              >
                {{ utilities.stringLimit(data.item, 30) }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <!-- ################# change_variant_description_to-->
        <v-col v-if="item.actionType == 'change_variant_description_to'" sm="12">
          <changeProductDescriptionTo type="change_variant_description_to" :index="ind" />
        </v-col>
        <v-col v-if="item.actionType == 'change_product_niche_to'" sm="6">
          <!-- <v-text-field :height="42" class="" v-model="item.valueArray" dense prefix=""></v-text-field> -->
          <v-combobox
            clearable
            :delimiters="[',']"
            @change="checkEmptyTag(item)"
            v-model="item.valueArray"
            :items="listTag"
            multiple
            chips
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click:close="removeTagNameAdd(data.item, ind)"
              >
                {{ utilities.stringLimit(data.item, 30) }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col v-if="item.actionType == 'change_product_type_to'" sm="6">
          <v-text-field :height="42" class="" v-model="item.value" dense prefix=""></v-text-field>
        </v-col>
        <!-- ################# Price -->
        <v-col v-if="item.actionType == 'change_price_to'" sm="6">
          <v-text-field
            :height="42"
            class=""
            v-model="item.value"
            dense
            :prefix="currency.currencyCode()"
          ></v-text-field>
        </v-col>
        <v-col v-if="item.actionType == 'increase_price_by_amount'" sm="6">
          <v-text-field
            :height="42"
            class=""
            v-model="item.value"
            dense
            :prefix="currency.currencyCode()"
          ></v-text-field>
        </v-col>
        <v-col v-if="item.actionType == 'decrease_price_by_amount'" sm="6">
          <v-text-field
            :height="42"
            class=""
            v-model="item.value"
            dense
            :prefix="currency.currencyCode()"
          ></v-text-field>
        </v-col>
        <v-col v-if="item.actionType == 'increase_price_by_percentage'" sm="6">
          <v-text-field :height="42" class="" v-model="item.value" dense prefix="%"></v-text-field>
        </v-col>
        <v-col v-if="item.actionType == 'decrease_price_by_percentage'" sm="6">
          <v-text-field :height="42" class="" v-model="item.value" dense prefix="%"></v-text-field>
        </v-col>
        <!-- ################# Compare Price -->
        <v-col v-if="item.actionType == 'change_compare_at_price_to'" sm="6">
          <v-text-field
            :height="42"
            class=""
            v-model="item.value"
            dense
            :prefix="currency.currencyCode()"
          ></v-text-field>
        </v-col>
        <v-col v-if="item.actionType == 'increase_compare_at_price_by_amount'" sm="6">
          <v-text-field
            :height="42"
            class=""
            v-model="item.value"
            dense
            :prefix="currency.currencyCode()"
          ></v-text-field>
        </v-col>
        <v-col v-if="item.actionType == 'decrease_compare_at_price_by_amount'" sm="6">
          <v-text-field
            :height="42"
            class=""
            v-model="item.value"
            dense
            :prefix="currency.currencyCode()"
          ></v-text-field>
        </v-col>
        <v-col v-if="item.actionType == 'set_compare_at_price_based_on_price'" sm="5">
          <v-text-field :height="42" class="" v-model="item.value" dense prefix="%"></v-text-field>
        </v-col>
        <v-col v-if="item.actionType == 'increase_compare_at_price_by_percentage'" sm="5">
          <v-text-field :height="42" class="" v-model="item.value" dense prefix="%"></v-text-field>
        </v-col>
        <v-col v-if="item.actionType == 'decrease_compare_at_price_by_percentage'" sm="5">
          <v-text-field :height="42" class="" v-model="item.value" dense prefix="%"></v-text-field>
        </v-col>
        <!-- ################# add_text_to_product_description
        <v-col v-if="Actions == 'add_text_to_product_description'" sm="4">
          <v-select
            v-model="Actions"
            item-text="title"
            item-value="value"
            placeholder="Select an action"
            :items="addTextDescription"
          ></v-select>
        </v-col>
        <v-col v-if="Actions == 'add_text_to_product_description'" sm="12">
          <ContentInput title="" :v-model="listAction.model" subtitle="Maximum 100.000 characters" />
        </v-col> -->
        <!-- ################# add_text_to_product_title
        <v-col v-if="Actions == 'add_text_to_product_title'" sm="3">
          <v-text-field></v-text-field>
        </v-col>
        <v-col v-if="Actions == 'add_text_to_product_title'" sm="3">
          <v-select
            v-model="Actions"
            item-text="title"
            item-value="value"
            placeholder="Select an action"
            :items="addTextDescription"
          ></v-select>
        </v-col>
        <p class="pl-3" v-if="Actions == 'add_text_to_product_title'" sm="3">
          If the new product's title exceeds 255 characters, its update request will be ignored while other product's
          titles are updated.
        </p> -->
        <!-- ################# remove_text_from_product_title
        <p style="line-height: 70px" v-if="Actions == 'remove_text_from_product_title'">Remove</p>
        <v-col v-if="Actions == 'remove_text_from_product_title'" sm="3">
          <v-text-field></v-text-field>
        </v-col>
        <p style="line-height: 70px" v-if="Actions == 'remove_text_from_product_title'">in product title.</p> -->
        <!-- ################# Add custom action -->
        <v-col v-if="item.actionType == 'add_custom_options'" sm="3">
          <v-select
            :items="selectBeforeAfter"
            placeholder="Before"
            item-value="value"
            item-text="title"
            v-model="position"
          ></v-select>
        </v-col>
        <p style="line-height: 70px" v-if="item.actionType == 'add_custom_options'">existing options</p>
        <div style="width: 100%" v-if="item.actionType == 'add_custom_options'" class="custom-option px-6 py-6 mx-4">
          <div v-for="(item1, ind1) in listFormAction[ind].actionOption" :key="ind1">
            <p>Custom Options {{ ind1 != 0 ? ind1 : '' }}</p>
            <div>
              <v-row class="my-0">
                <v-col class="py-0" sm="12"><h4>Name</h4></v-col>
                <v-col class="py-0" sm="12">
                  <v-text-field v-model="item1.name" hide-details=""></v-text-field>
                </v-col>
                <v-col
                  v-if="item1.name == ''"
                  sm="12"
                  class="pb-0 pt-2"
                  style="line-height: 12px; word-break: break-word; color: red; font-size: 12px"
                  >Please finish this field</v-col
                >
                <v-col class="py-0 mt-5" sm="12"><h4>Label (display on storefront)</h4></v-col>
                <v-col class="py-0" sm="12" style="height: 215px">
                  <quill-editor
                    class="disciplineQE"
                    v-model="item1.bodyHtml"
                    :options="{
                      theme: 'snow',
                    }"
                  />
                </v-col>
                <v-col
                  v-if="item1.bodyHtml == ''"
                  sm="12"
                  class="pb-0 pt-2"
                  style="line-height: 12px; word-break: break-word; color: red; font-size: 12px"
                  >Please finish this field</v-col
                >
                <v-col sm="6"><h4>Type</h4></v-col>
                <v-col sm="6"><h4>Required?</h4></v-col>
                <v-col class="py-0" sm="6"
                  ><v-select
                    v-model="item1.type"
                    :items="listFormAction[ind].customType"
                    item-value="value"
                    item-text="title"
                  ></v-select
                ></v-col>
                <v-col class="py-0" sm="6">
                  <v-radio-group class="mt-0" v-model="item1.validations.required" row>
                    <v-radio label="No" :value="false"></v-radio>
                    <v-radio label="Yes" :value="true"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col class="py-0" sm="6"><h4>Placeholder (optional)</h4></v-col>
                <v-col class="py-0" sm="6"><h4>Max length</h4></v-col>
                <v-col class="py-0" sm="6"
                  ><v-text-field v-model="item1.placeholder" hide-details=""></v-text-field
                ></v-col>
                <v-col class="py-0" sm="6">
                  <v-text-field
                    type="number"
                    v-model.number="item1.validations.max_length"
                    hide-details=""
                  ></v-text-field>
                </v-col>
                <v-col class="pb-0 pt-2" v-if="item1.validations.max_length == ''" sm="6"></v-col>
                <v-col
                  v-if="item1.validations.max_length == ''"
                  sm="6"
                  class="pb-0 pt-2"
                  style="line-height: 12px; word-break: break-word; color: red; font-size: 12px"
                  >Please finish this field</v-col
                >
                <v-col class="py-0" sm="6"><h4>Default value (prefill on storefront)</h4></v-col>
                <v-col class="py-0" sm="12"><v-text-field v-model="item1.default_value"></v-text-field></v-col>
                <v-col class="py-0" sm="6"><h4>Allow the following characters</h4></v-col>
                <v-col class="py-0" sm="12">
                  <v-row>
                    <v-col sm="2">
                      <v-checkbox
                        v-model="item1.validations.allowed_characters"
                        label="Characters"
                        value="characters"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col sm="2">
                      <v-checkbox
                        v-model="item1.validations.allowed_characters"
                        label="Numbers"
                        value="numbers"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col sm="3">
                      <v-checkbox
                        v-model="item1.validations.allowed_characters"
                        label="Special Characters"
                        value="special_characters"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col sm="2">
                      <v-checkbox
                        v-model="item1.validations.allowed_characters"
                        label="Emoji"
                        value="emoji"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="item1.validations.allowed_characters == ''"
                  sm="6"
                  class="pb-0 pt-2"
                  style="line-height: 12px; word-break: break-word; color: red; font-size: 12px"
                  >You have to select at least one option.</v-col
                >
                <v-col class="d-flex justify-end py-0"
                  ><v-btn class="delete-btn" color="primary" outlined @click="reduceOption(ind, ind1)"
                    ><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn
                  ></v-col
                >
              </v-row>
              <v-col v-if="item.actionType == 'add_custom_options'" sm="12"><v-divider></v-divider></v-col>
            </div>
          </div>
          <div>
            <v-col class="px-4" sm="2">
              <v-btn color="primary" @click="addOption(ind)">Add option</v-btn>
            </v-col>
          </div>
        </div>
        <v-spacer></v-spacer>
        <!-- ################ Thêm action nào thì viết tiếp vào dưới -->
        <v-col sm="1" class=""
          ><v-btn class="delete-btn" color="primary" outlined v-if="ind > 0" @click="reduceAction(ind)"
            ><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn
          ></v-col
        >
      </v-row>
      <v-col class="px-0 py-0"><v-btn color="primary" @click="addAction">Add Action</v-btn></v-col>
    </div>
    <v-row class="mt-1">
      <v-col class="d-flex justify-end">
        <v-btn color="primary" :disabled="checkDiableBtn" @click="previewBulkUpdate">Preview Bulk Update</v-btn>
      </v-col>
    </v-row>
    <v-dialog width="550" v-model="showStartBulkUpdate">
      <v-card>
        <v-card-title class="text-h5"> Update {{ count }} product </v-card-title>
        <v-card-text>
          Are you sure you want to update {{ count }} product? This action cannot be reversed.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="cancelBulkUpdate"> Cancel </v-btn>
          <v-btn :loading="isLoadingButton" color="primary" @click="startBulkUpdate"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showProducts" width="900" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline"> Preview Bulk Update </v-card-title>
        <div class="close-icon" @click="showProducts = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <div class="pl-6 mb-3">
          <!-- <strong>Total:</strong> {{ totalProducts }} products, <strong>Available:</strong> {{ count ? count : 0 }}
          {{ count > 1 ? 'products' : 'product' }}, <strong>Reject:</strong> {{ rejectProducts }}
          {{ rejectProducts > 0 ? 'products' : 'product' }} -->
          <strong>Total:</strong> {{ count }} products
          <v-progress-circular v-if="circle" indeterminate color="primary"></v-progress-circular>
        </div>
        <h4 class="pl-6 mb-3">Filter</h4>
        <v-card-text class="mb-3 pb-0"> Products that match {{ filterType }} below conditions: </v-card-text>
        <v-card-text class="mb-3 pb-0" v-for="(item, ind) in filterOption" :key="ind">
          <v-icon style="padding-bottom: 2px">mdi-circle-medium</v-icon>
          {{ item.column != '' ? changeColumnName(item.column) : '' }}
          {{ item.relation != '' ? changeColumnRelation(item.relation) : '' }} {{ item.condition }}
        </v-card-text>
        <div class="pl-2" style="color: rgba(0, 0, 0, 0.6)" v-if="isUpdateVariant">
          <v-card-text class="pt-0"> Products that match {{ variantType }} below conditions: </v-card-text>
          <v-card-text class="pb-3 pt-0" v-for="(item, ind) in variantOption" :key="ind">
            <v-icon style="padding-bottom: 2px">mdi-circle-medium</v-icon>
            {{ item.column != '' ? changeColumnName(item.column) : '' }}
            {{ item.relation != '' ? changeColumnRelation(item.relation) : '' }} {{ item.condition }}
          </v-card-text>
        </div>
        <!-- Action -->
        <h4 class="pl-6 mb-3">Action</h4>
        <v-card-text class="pb-0 mb-3"> Below actions will be taken from top to bottom: </v-card-text>
        <v-card-text class="pb-0 mb-3 pl-6" v-for="(item, indd) in listFormAction" :key="indd + '1'">
          <v-icon style="padding-bottom: 2px">mdi-circle-medium</v-icon>
          {{ changeActionName(item.actionType) }}
          <!-- Value (actionOption.name) của action: add custom action -->
          {{ getNameAddCustomAction(item) }}
          <!-- Condition -->
          {{ item.condition }}
          <!-- Value -->
          <span
            v-if="listInfor.actions[indd] && listInfor.actions[indd].command !== 'change_product_niche_to'"
            v-html="listInfor.actions[indd].value"
          ></span>
          <!-- với change_product_niche_to thì value lưu dạng mảng ['value'] nên cần convert -->
          <span
            v-if="listInfor.actions[indd] && listInfor.actions[indd].command === 'change_product_niche_to'"
            v-html="listInfor.actions[indd].value"
          ></span>
          {{ displayPrefix(listInfor.actions[indd]) }}
          {{ item.actionType.includes('add_custom_options') ? 'before existing custom options' : '' }}
        </v-card-text>
        <h4 class="pl-6 mt-2">Sample matching products</h4>
        <v-simple-table fixed-header class="px-6" :height="productsPreview.length > 0 ? 400 : 100">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left px-0" style="width: 400px">PRODUCT</th>
                <th class="text-right px-0" style="width: 200px">TYPE</th>
                <th class="text-center px-0" style="width: 200px">VENDOR</th>
              </tr>
            </thead>
            <tbody style="position: relative">
              <!-- <v-progress-circular class="progress-circle" indeterminate color="primary"></v-progress-circular> -->
              <tr v-for="(item, ind) in productsPreview" :key="ind">
                <td class="py-3">
                  <v-row column>
                    <v-col sm="4"
                      ><v-avatar>
                        <img
                          :src="
                            item.images && item.images[0].src
                              ? imageHelpers.url(item.images[0].src, { width: 50, height: 50 })
                              : '@/assets/images/no-image.png'
                          "
                        /> </v-avatar
                    ></v-col>
                    <v-col sm="8" class="text-left">{{ item.title }}</v-col>
                  </v-row>
                </td>
                <!-- <td>{{ item.images[0] }}</td> -->
                <td class="text-right px-0">{{ item.productType }}</td>
                <td class="text-center px-0">{{ item.vendor }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-col
          cols="12"
          v-if="!isLoading && productsPreview && productsPreview.length === 0"
          class="d-flex justify-center"
        >
          <Empty />
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="cancelPreBulkUpdate"> Cancel </v-btn>
          <v-btn :loading="isLoadingButton" color="primary" @click="startPreBulkUpdate"> Start Bulk Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import ContentInput from '@/views/website/components/Editor';
import changeProductDescriptionTo from './Product/changeProductDescriptionTo';
// import changePrice from './Price/changePrice';
import { imageFake } from '@/const/image';
import actionBulkUpdates from './actionBulkUpdates';
import utilities from '@/helpers/utilities';
import formAddCustomOption from './formAddCustomOption'; // là obj to nhất, chưa tất cả các option
import { bulkUpdateApi } from '@/apis/bulkupdate';
import defaultAction from './defaultAction'; // là phần Action đầu tiên chỉ có 1 select chưa chọn action nào cả
import imageHelpers from '@/helpers/image';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import { log } from 'handlebars';
import resourceListMixin from '@/mixins/resource-list';
import { eventBus } from '../../../main.js';
import { productApi } from '@/apis/product';
import currency from '@/helpers/currency';

export default {
  mixins: [resourceListMixin],
  components: {
    changeProductDescriptionTo,
    // changePrice,
  },
  data() {
    return {
      currency,
      inputRemove: [],
      inputAdd: [],
      listTag: [],
      utilities,
      productInput: {
        changeProductDes: '',
      },
      resourceType: 'bulkupdates',
      imageFake,
      showProducts: false, // để hiện dialog các sp sau khi filter
      isUpdateVariant: false,
      selectBeforeAfter: [
        {
          title: 'Before',
          value: 'before',
        },
        {
          title: 'After',
          value: 'after',
        },
      ],
      filterOption: [
        {
          column: 'title',
          relation: 'contain',
          condition: '',
        },
      ],
      variantOption: [
        {
          column: '',
          relation: '',
          condition: '',
        },
      ],
      itemsVariant1: [
        {
          title: 'Variant title',
          value: 'variant.title',
          compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
        },
        {
          title: 'Variant price',
          value: 'variant.price',
          compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        },
        {
          title: 'Variant compare at price',
          value: 'variant.compareAtPrice',
          compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        },
        {
          title: 'Variant weight',
          value: 'variant.weight',
          compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        },
      ],
      itemsVariant2: [
        {
          title: 'contains',
          value: 'contain',
          customDisabledFilter: false,
        },
        {
          title: 'does not contains',
          value: 'notContain',
          customDisabledFilter: false,
        },
        {
          title: 'starts with',
          value: 'startWith',
          customDisabledFilter: false,
        },
        {
          title: 'ends with',
          value: 'endWith',
          customDisabledFilter: false,
        },
        {
          title: 'is equal to',
          value: 'equal',
          customDisabledVariant: false,
        },
        {
          title: 'is not equal to',
          value: 'notEqual',
          customDisabledVariant: false,
        },
        {
          title: 'is greater than',
          value: 'greaterThan',
          customDisabledVariant: false,
        },
        {
          title: 'is less than',
          value: 'lessThan',
          customDisabledVariant: false,
        },
      ],
      itemsFilter1: [
        {
          title: 'Product title',
          value: 'title',
          compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
        },
        {
          title: 'Product type',
          value: 'productType',
          compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
        },
        {
          title: 'Product tag',
          value: 'tags',
          // compare: ['equal', 'contain', 'notContain'],
          compare: ['contain'],
        },
        {
          title: 'Product vendor',
          value: 'vendor',
          compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
        },
        // {
        //   title: 'Product price',
        //   value: 'variant.price',
        //   compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        // },
        // {
        //   title: 'Compare at price',
        //   value: 'variant.compareAtPrice',
        //   compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        // },
        // {
        //   title: 'Weight',
        //   value: 'variant.weight',
        //   compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        // },
        // {
        //   title: 'Inventory Stock',
        //   value: 'inventoryQuantity',
        //   compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
        // },
        // {
        //   title: 'Variant title',
        //   value: 'variant.title',
        //   compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
        // },
        // {
        //   title: 'Collection',
        //   value: 'collections',
        //   compare: ['equal', 'notEqual'],
        // },
      ],
      itemsFilter2: [
        {
          title: 'contains',
          value: 'contain',
          customDisabledFilter: false,
        },
        {
          title: `doesn't contains`,
          value: 'notContain',
          customDisabledFilter: false,
        },
        {
          title: 'is equal to',
          value: 'equal',
          customDisabledFilter: false,
        },
        {
          title: 'is not equal to',
          value: 'notEqual',
          customDisabledFilter: false,
        },
        {
          title: 'is greater than',
          value: 'greaterThan',
          customDisabledFilter: false,
        },
        {
          title: 'is less than',
          value: 'lessThan',
          customDisabledFilter: false,
        },
        {
          title: 'starts with',
          value: 'startWith',
          customDisabledFilter: false,
        },
        {
          title: 'ends with',
          value: 'endWith',
          customDisabledFilter: false,
        },
      ],
      listAction: actionBulkUpdates.action,
      listInfor: {
        actions: [
          {
            extra: {
              extraType: '',
            },
            command: '',
            value: '',
          },
        ],
        filter: {},
      },
      filterType: 'all',
      variantType: 'all',
      imageHelpers,
      isLoadingButton: false,
      position: 'before',
      listFormAction: formAddCustomOption.form,
      testForm: [...defaultAction.form],
      productsPreview: '',
      showStartBulkUpdate: false,
      count: '',
      totalProducts: '',
      isClickedCancel: false,
      circle: true,
      checkNotEqualType: [],
    };
  },
  methods: {
    checkActionType(item) {
      if (
        item.actionType == 'increase_compare_at_price_by_percentage' ||
        item.actionType == 'decrease_compare_at_price_by_percentage' ||
        item.actionType == 'set_compare_at_price_based_on_price'
      ) {
        return 5;
      } else {
        return 4;
      }
    },
    checkEmptyTag(item) {
      // những cái "" và những cái trùng nhau bị loại
      item.valueArray = item.valueArray.filter(i => i.trim() != '');
    },
    // remove chip ở phần select input với điều kiện add hoặc remove là equals
    removeTagNameRemove(item, ind) {
      const index = this.listFormAction[ind].valueArray.indexOf(item);
      if (index >= 0) {
        this.listFormAction[ind].valueArray.splice(index, 1);
      }
    },
    removeTagNameAdd(item, ind) {
      const index = this.listFormAction[ind].valueArray.indexOf(item);
      if (index >= 0) {
        this.listFormAction[ind].valueArray.splice(index, 1);
      }
    },
    // để mỗi khi select action khác thì data gửi lên api change theo
    checkAction(element, index) {
      if (element.actionType == 'remove_tags') {
        this.listFormAction[index].condition = 'equals';
        this.listInfor.actions[index].extra.extraType = 'equals';
      } else {
        this.listInfor.actions[index].extra = {};
      }
      this.listFormAction[index].value = '';
      this.listFormAction[index].valueArray = '';
    },
    // hàm disable các item trong select Filter Option
    checkDisableFilterCompare(item, column) {
      let availableCompare = this.itemsFilter1.find(i => i.value === column);
      if (availableCompare) {
        let check = availableCompare.compare.find(av => av === item.value);
        if (check) {
          return {
            disable: false,
          };
        } else {
          return {
            disable: true,
          };
        }
      } else {
        return {
          disable: false,
        };
      }
    },
    // hàm disable các item trong select Filter Variant
    checkDisableVariantCompare(item, column) {
      let availableCompare = this.itemsVariant1.find(i => i.value === column);
      if (availableCompare) {
        let check = availableCompare.compare.find(av => av === item.value);
        if (check) {
          return {
            disable: false,
          };
        } else {
          return {
            disable: true,
          };
        }
      } else {
        return {
          disable: false,
        };
      }
    },
    cancelBulkUpdate() {
      this.showStartBulkUpdate = false;
    },
    cancelPreBulkUpdate() {
      this.productsPreview = '';
      this.showProducts = false;
      this.isClickedCancel = true;
    },
    startPreBulkUpdate() {
      this.showStartBulkUpdate = true;
    },
    async startBulkUpdate() {
      await bulkUpdateApi.post(this.listInfor);
      // this.listFormAction.actionOption = [];
      let defaultFilter1 = [
        {
          column: 'title',
          relation: 'contain',
          condition: '',
        },
      ];
      let defaultFilter2 = [
        {
          column: '',
          relation: '',
          condition: '',
        },
      ];
      this.listInfor.actions = [];
      this.filterOption = defaultFilter1;
      this.variantOption = defaultFilter2;
      // để khi ấn start bu thì reset form to nhất, chứa tất cả các action về ban đầu
      this.listFormAction = [...this.testForm];
      this.listFormAction.forEach(i => {
        i.actionType = '';
      });
      this.listFormAction.forEach(i => {
        i.actionOption = [
          {
            name: '',
            bodyHtml: '',
            type: 'textField',
            placeholder: '',
            default_value: '',
            validations: {
              allowed_characters: ['characters', 'numbers'],
              max_length: 30,
              required: true,
            },
          },
        ];
      });
      eventBus.$emit('getCount', this.count);
      this.isUpdateVariant = !this.isUpdateVariant;
      this.showProducts = false;
      this.$router.push({ name: 'website.bulkupdates' });
    },
    async previewBulkUpdate() {
      this.showProducts = true;
      this.circle = true;
      console.log(this.listFormAction);
      console.log(this.listInfor);
      // if (!this.isClickedCancel) {
      for (let index = 0; index < this.listFormAction.length; index++) {
        if (this.listFormAction[index].actionType == 'add_custom_options') {
          const element = this.listFormAction[index];
          if (element.actionType == 'add_custom_options') {
            this.listInfor.actions[index] = {
              command: 'add_custom_options',
              extra: {
                add_custom_option: {
                  list: element.actionOption,
                  position: this.position,
                },
              },
              value: '',
            };
          }
        } else {
          this.listInfor.actions[index].command = this.listFormAction[index].actionType;
          console.log(this.listFormAction[index].actionType);
          if (this.listFormAction[index].actionType == 'change_product_niche_to') {
            let arr = [this.listFormAction[index].valueArray].join(',');
            console.log('..', this.listFormAction[index].valueArray);
            console.log(arr);
            this.$set(this.listInfor.actions[index], 'value', arr);
          } else {
            let stringData = this.listFormAction[index].value;
            this.$set(this.listInfor.actions[index], 'value', stringData);
            // this.$forceUpdate();
          }
          if (
            (this.listFormAction[index].actionType == 'remove_tags' &&
              this.listFormAction[index].condition == 'equals') ||
            this.listFormAction[index].actionType == 'add_tags'
          ) {
            let arrayData = this.listFormAction[index].valueArray.join(',');
            console.log(arrayData);
            this.$set(this.listInfor.actions[index], 'value', arrayData);
            // this.$forceUpdate();
          }
          if (this.listFormAction[index].actionType == 'remove_tags') {
            this.listInfor.actions[index].extra.extraType = this.listFormAction[index].condition;
            this.$forceUpdate();
          }
        }
      }
      // }
      this.listInfor.filter.rules = this.filterOption;
      this.listInfor.filter.filterType = this.filterType;
      // nếu filter theo Variant
      if (this.isUpdateVariant) {
        this.listInfor.variantFilter = {};
        this.listInfor.variantFilter.rules = this.variantOption;
        this.listInfor.variantFilter.filterType = this.variantType;
        this.listInfor.isUsingVariantFilter = true;
      }
      if (!this.isUpdateVariant) {
        this.listInfor.isUsingVariantFilter = false;
      }
      try {
        let res = await bulkUpdateApi.getProduct(this.listInfor);
        console.log(`res.data.products`, res.data.products);
        this.productsPreview = res.data.products;
        let res1 = await bulkUpdateApi.getCount(this.listInfor);
        console.log(res1);
        this.count = res1.data.count;
        this.circle = false;
        // console.log(res.data);
      } catch (error) {}
      this.isClickedCancel = true;
    },
    addOption(i) {
      this.listFormAction[i].actionOption.push({
        name: '',
        bodyHtml: '',
        type: 'textField',
        placeholder: '',
        default_value: '',
        validations: {
          allowed_characters: ['characters', 'numbers'],
          max_length: 30,
          required: true,
        },
      });
    },
    // a là index của cả form to, gồm 30 action, b là index của các form trong action Add custom option
    reduceOption(a, b) {
      this.listFormAction[a].actionOption.splice(b, 1);
    },
    addAction() {
      // bên trong các ele ở dưới lại có các obj để lưu data
      this.listInfor.actions.push({
        command: '',
        extra: {
          extraType: '',
        },
        value: '',
      });
      // là các ele trong productMassUpdate.actions ở request payload
      this.listFormAction.push({
        actionType: '',
        condition: '',
        value: '', // value để lưu các input dạng string (trong v-text-field)
        valueArray: [], // valueArray để lưu input dạng mảng ( trong v-autocomplete, v-combobox)
        // 2 cái này là dùng cho Add custom option
        customType: [
          {
            title: 'Text field',
            value: 'textField',
          },
          {
            title: 'Text area',
            value: 'textArea',
          },
          {
            title: 'Image',
            value: 'image',
          },
          {
            title: 'Radio buttons',
            value: 'radioButtons',
          },
          {
            title: 'Droplist',
            value: 'droplist',
          },
          {
            title: 'Checkbox',
            value: 'checkbox',
          },
          {
            title: 'Picture choice',
            value: 'pictureChoice',
          },
        ],
        actionOption: [
          {
            name: '',
            bodyHtml: '',
            type: 'textField',
            placeholder: '',
            default_value: '',
            validations: {
              allowed_characters: ['characters', 'numbers'],
              max_length: 30,
              required: true,
            },
          },
        ],
        // cái này là condition khi remove tag
        listConditionTag: [
          {
            title: 'is equal to',
            value: 'equals',
          },
          {
            title: 'contains',
            value: 'contains',
          },
          {
            title: `doesn't contains `,
            value: 'not_contains',
          },
        ],
      });
    },
    // i là index của action đang chọn, trong 30 action có thể chọn
    reduceAction(ind) {
      this.listFormAction.splice(ind, 1);
      this.listInfor.actions.splice(ind, 1);
    },
    turnUpdateVariant() {
      this.isUpdateVariant = !this.isUpdateVariant;
    },
    reduceFilterOption(i) {
      this.filterOption.splice(i, 1);
    },
    addFilterOption() {
      this.filterOption.push({
        column: 'title',
        relation: 'contain',
        condition: '',
      });
    },
    addVariantOption() {
      this.variantOption.push({
        column: '',
        relation: '',
        condition: '',
      });
    },
    reduceVariantOption(i) {
      this.variantOption.splice(i, 1);
    },
    // để khi load trang thì lấy tất cả tag hiện có trong store ra, dùng cho action add tag
    async loadProductTags() {
      this.isLoading = true;
      try {
        let params = {
          limit: 50,
        };
        let data = await productApi.getProductTags(params);
        this.listTag = data.data.tags;
        this.listTag = this.listTag.filter((item, index) => {
          return this.listTag.indexOf(item.trim()) == index;
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
  },
  watch: {
    listFormAction: {
      handler: function(val, oldVal) {
        // console.log(oldVal.price ? oldVal.price : '');
      },
      deep: true,
    },
  },
  // khi chuyển màn thì clear lại về form ban đầu
  mounted() {
    this.listFormAction = [
      {
        // list: [],
        actionType: '',
        condition: '',
        value: '',
        valueArray: [],
        customType: [
          {
            title: 'Text field',
            value: 'textField',
          },
          {
            title: 'Text area',
            value: 'textArea',
          },
          {
            title: 'Image',
            value: 'image',
          },
          {
            title: 'Radio buttons',
            value: 'radioButtons',
          },
          {
            title: 'Droplist',
            value: 'droplist',
          },
          {
            title: 'Checkbox',
            value: 'checkbox',
          },
          {
            title: 'Picture choice',
            value: 'pictureChoice',
          },
        ],
        actionOption: [
          {
            name: '',
            bodyHtml: '',
            type: 'textField',
            placeholder: '',
            default_value: '',
            validations: {
              allowed_characters: ['characters', 'numbers'],
              max_length: 30,
              required: true,
            },
          },
        ],
        listConditionTag: [
          {
            title: 'is equal to',
            value: 'equals',
          },
          {
            title: 'contains',
            value: 'contains',
          },
          {
            title: `doesn't contains `,
            value: 'not_contains',
          },
        ],
      },
    ];
    // hiện tất cả các tag hiện có của tất cả product, tạm thời ẩn do nhiều quá treo trình duyệt
    // this.loadProductTags();
  },
  computed: {
    // số lượng sp reject
    rejectProducts() {
      let a;
      if (this.count) {
        return (a = this.totalProducts - parseInt(this.count));
      } else {
        return (a = this.totalProducts);
      }
    },
    checkDiableBtn: function() {
      let c;
      let requireFilterValue = this.filterOption.find(i => {
        if (i.condition == '') {
          return true;
        }
      });
      let requireAction = this.listFormAction.find(i =>
        // if(i.actionType == 'add_custom_options') {
        {
          if (
            i.actionOption.find(
              y =>
                y.name == '' ||
                y.bodyHtml == '' ||
                y.validations.max_length == '' ||
                y.validations.allowed_characters.length == 0,
            ) &&
            i.actionType == 'add_custom_options'
          ) {
            return true;
          }
          if (i.actionType == '') {
            return true;
          }
        },
      );
      let requireActionValue = this.listFormAction.find(i => {
        if (i.value == '' && i.valueArray == [] && i.actionType != 'add_custom_options') {
          return true;
        }
      });
      if (requireFilterValue || requireAction || requireActionValue) {
        c = true;
      } else {
        c = false;
      }
      return c;
    },
  },
};
</script>

<style lang="scss" scoped>
.learn-more:hover {
  text-decoration: underline;
  cursor: pointer;
}
.custom-option {
  border: solid 1px #e6e6e6;
}
.delete-btn {
  min-width: 40px !important;
  width: 40px !important;
}
.disciplineQE {
  height: 150px;
}
.progress-circle {
  position: absolute;
  top: 290px;
  left: 410px;
}
.v-select__selection--comma {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
</style>
<style>
.v-menu__content {
  z-index: 101 !important;
}
</style>
