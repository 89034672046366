const form = [
  {
    list: [],
    actionType: '',
    customType: [
      {
        title: 'Text field',
        value: 'textField',
      },
      {
        title: 'Text area',
        value: 'textArea',
      },
      {
        title: 'Image',
        value: 'image',
      },
      {
        title: 'Radio buttons',
        value: 'radioButtons',
      },
      {
        title: 'Droplist',
        value: 'droplist',
      },
      {
        title: 'Checkbox',
        value: 'checkbox',
      },
      {
        title: 'Picture choice',
        value: 'pictureChoice',
      },
    ],
    actionOption: [
      {
        name: '',
        bodyHtml: '',
        type: '',
        placeholder: '',
        default_value: '',
        validations: {
          allowed_characters: [],
          max_length: '',
          required: '',
        },
      },
    ],
  },
];
export default {
  form,
};
