const form = [
  {
    // list: [],
    actionType: '',
    condition: '',
    value: '',
    valueArray: [],
    // select type bên trong form của add custom option
    customType: [
      {
        title: 'Text field',
        value: 'textField',
      },
      {
        title: 'Text area',
        value: 'textArea',
      },
      {
        title: 'Image',
        value: 'image',
      },
      {
        title: 'Radio buttons',
        value: 'radioButtons',
      },
      {
        title: 'Droplist',
        value: 'droplist',
      },
      {
        title: 'Checkbox',
        value: 'checkbox',
      },
      {
        title: 'Picture choice',
        value: 'pictureChoice',
      },
    ],
    actionOption: [
      {
        name: '',
        bodyHtml: '',
        type: 'textField',
        placeholder: '',
        default_value: '',
        validations: {
          allowed_characters: ['characters', 'numbers'],
          max_length: '',
          required: true,
        },
      },
    ],
    listConditionTag: [
      {
        title: 'is equal to',
        value: 'equals',
      },
      {
        title: 'contains',
        value: 'contains',
      },
      {
        title: `doesn't contains `,
        value: 'not_contains',
      },
    ],
  },
];
export default {
  form,
};
