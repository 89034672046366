<template>
  <div>
    <v-col class="py-0 pl-0" sm="12">
      <ContentInput title="" model="description" subtitle="Maximum 100.000 characters" />
    </v-col>
  </div>
</template>

<script>
import ContentInput from '@/views/website/components/Editor';

export default {
  data() {
    return {
      attributes: {
        description: '',
      },
    };
  },
  components: {
    ContentInput,
  },
  props: {
    index: {
      type: Number,
    },
  },
  watch: {
    // để khi input thay đổi thì nó model luôn với listInfor
    // listInfor là data gửi lên api
    attributes: {
      handler: function(val, oldVal) {
        this.$parent.listFormAction[this.index].value = this.attributes.description;
      },
      deep: true,
    },
  },
};
</script>

<style></style>
